import {
    SET_UID,
    SET_APIKEY,
    SET_IS_LOGGED_IN,
    LOG_OUT
} from "../constants/ActionTypes";
import { updateObject } from "./helpers";
import Cookies from "js-cookie";

if (!Cookies.getJSON("apikey")) {
    Cookies.set("apikey", "guest");
}

if (!Cookies.getJSON("uid")) {
    Cookies.set("uid", -1);
}

if (!Cookies.getJSON("isLoggedIn")) {
    Cookies.set("isLoggedIn", 0);
}

const initialState = {
    apikey: Cookies.getJSON("apikey") ? Cookies.getJSON("apikey") : "guest",
    uid: Cookies.getJSON("uid") ? Cookies.getJSON("uid") : -1,
    isLoggedIn: Cookies.getJSON("isLoggedIn") ? Cookies.getJSON("isLoggedIn") : 0
};

function setUid(state, action) {
    return updateObject(state, { uid: action.uid });
}

function setApikey(state, action) {
    return updateObject(state, { apikey: action.apikey });
}

function setIsLoggedIn(state, action) {
    return updateObject(state, { isLoggedIn: action.isLoggedIn });
}

function clearAuthState(state) {
    return updateObject(state, {
        apikey: "guest",
        uid: -1,
        isLoggedIn: false
    });
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_UID:
            return setUid(state, action);
        case SET_APIKEY:
            return setApikey(state, action);
        case SET_IS_LOGGED_IN:
            return setIsLoggedIn(state, action);
        case LOG_OUT:
            return clearAuthState(state, action);
        default:
            return state;
    }
};

export default auth;
