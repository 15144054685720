
export default {
    groups: [
        {
            key: "12", title: "Studios - Williamsburg", links: [
                [ "http://rootnyc.com/studios/bkn/", "Explore All" ],
                [ "http://rootnyc.com/studios/bkn/studio-1", "Studio 1" ],
                [ "http://rootnyc.com/studios/bkn/studio-2", "Studio 2" ],
                [ "http://rootnyc.com/studios/bkn/studio-3", "Studio 3" ],
                [ "http://rootnyc.com/studios/bkn/studio-4", "Studio 4" ],
                [ "http://rootnyc.com/studios/bkn/cafe-lobby", "Cafe Lobby" ],
                [ "http://rootnyc.com/studios/bkn/garden", "Garden" ]
            ]
        },

        {
            key: "14", title: "Services", links: [
                [ "http://rootnyc.com/equipment/lighting-grip-and-electric-equipment-rentals/", "Equipment" ],
                [ "http://rootnyc.com/equipment/digital/", "Digital" ],
                [ "http://rootnyc.com/equipment/motion", "Motion" ],
                [ "http://rootnyc.com/equipment/production", "Production" ]
            ]
        },

        {
            key: "15", title: "Rentals", url: "/equipment/rentals/"
        },

        {
            key: "17", title: "Events", links: [
                [ "http://rootnyc.com/spaces/", "Spaces" ],
                [ "http://rootnyc.com/equipment/rentals", "Rentals" ]
            ]
        }
    ]
};
