const initialState = {
    initialized: false,
    flash_message: null,
    flash_message_type: "info",
    flash_message_path: null
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case "INIT_SUCCESS":
            return {
                ...state,
                initialized: true
            };

        case "SET_FLASH_MESSAGE":
            return {
                ...state,
                flash_message: action.message,
                flash_message_type: action.message_type ?? "info",
                flash_message_path: action.path
            };

        case "CLEAR_FLASH_MESSAGE":
            return {
                ...state,
                flash_message: null,
                flash_message_type: "info",
                flash_message_path: null
            };
        default:
            return state;
    }
}
