import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { NavLink as Hyper, useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

/**
 * A navigation link that opens external URLs in a new tab
 *
 * @param target
 * @param label
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
function NavLink({ link: [ target, label ], onClick }) {
    if (target.startsWith("http")) {
        return <li>
            <a href={target} rel="external" target="rootnyc_mainsite">
                {label}
            </a>
        </li>;
    } else {
        return <li>
            <Hyper to={target} onClick={onClick}>
                {label}
            </Hyper>
        </li>;
    }
}

NavLink.propTypes = {
    link: PropTypes.any,
    onClick: PropTypes.func
};

const NavGroup = props => {
    const history = useHistory(),
        [ groupOpen, setGroupOpen ] = useState(false),
        { title, links, url, clickHandle, mainNavOpen } = props;

    useEffect(() => {
        if (!mainNavOpen) {
            setGroupOpen(false);
        }
    }, [ mainNavOpen ]);

    const subMenuClasses = cx({
        "c-site-nav-submenu": true,
        "js-submenu": true,
        "is-open": groupOpen
    });

    const handleClick = useCallback(() => {
        if (links?.length > 0) {
            setGroupOpen(was => !was);
        } else if (url) {
            setGroupOpen(false);
            clickHandle();
            history.push(url);
        }
    }, [ clickHandle, history, links?.length, url ]);

    return (
        <li>
            <button
                type="button"
                style={ { outline: "none", padding: 0, backgroundColor: "transparent", border: 0 } }
                onClick={ handleClick } className="c-site-nav-label js-submenu-toggle">

                { title }
            </button>

            <nav className={ subMenuClasses }>
                {
                    links?.map?.((link, index) =>
                        <NavLink key={index} link={link} onClick={() => {
                            setGroupOpen(false);
                            clickHandle();
                        }}/>
                    )
                }
            </nav>
        </li>
    );
};

export default NavGroup;
