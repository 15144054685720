import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import footerImg from "../img/Studios_BKN_Cafe_Lobby-dark.jpg";
import { Link as Hyper } from "react-router-dom";

import "../styles/components/footer.scss";

const Footer = () => {
    const style = { backgroundImage: `url(${footerImg})` };

    return (
        <footer className="l-content-container c-site-footer js-site-footer u-img-fill-area" style={ style }>
            <div className="c-site-footer-contact">
                <div className="c-site-footer-heading">
                    Let's Connect.
                    Collaborate.
                    Create.
                </div>

                <Hyper to="/contact" className="u-hover-link u-hover-link-white">
                    Contact Us
                </Hyper>
            </div>

            <div className="c-site-footer-newsletter">
                <div className="c-site-footer-newsletter-heading">
                    Our<br />
                    Newsletter
                </div>

                <p>Subscribe to our newsletter and always be the first to know about what's happening at ROOT.</p>

                <div id="mc_embed_signup">
                    <form
                        action="//rootnyc.us8.list-manage.com/subscribe/post?u=8dbb27d3d250ce738105f92ca&amp;id=118fe0b5ab"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate c-site-footer-newsletter-form"
                        target="_blank"
                        noValidate>

                        <div id="mc_embed_signup_scroll" style={ { display: "inline" } }>
                            <div className="c-site-footer-newsletter-form-fields">
                                <input
                                    id="mce-EMAIL"
                                    name="EMAIL"
                                    className="required white email"
                                    type="email"
                                    style={ { display: "inline" } }
                                    placeholder="Enter your email"
                                />

                                <input
                                    id="mc-embedded-subscribe"
                                    name="subscribe"
                                    className="c-btn c-btn-white c-btn-mini"
                                    type="submit"
                                    value="Subscribe"
                                />
                            </div>

                            <div id="mce-responses">
                                <div className="response" id="mce-error-response" style={ { display: "none" } }></div>
                                <div className="response" id="mce-success-response" style={ { display: "none" } }></div>
                            </div>

                            <div style={ { position: "absolute", left: "-5000px" } }>
                                <input type="text" name="b_8dbb27d3d250ce738105f92ca_118fe0b5ab" tabIndex="-1" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="c-site-footer-get-in-touch">
                <div className="c-site-footer-social-links">
                    <a href="https://www.facebook.com/ROOT.NYC.BKN" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faFacebookF } />
                    </a>

                    <a href="https://www.linkedin.com/company/root-studios-nyc-/" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faLinkedinIn } />
                    </a>

                    <a href="https://twitter.com/rootstudios" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faTwitter } />
                    </a>

                    <a href="https://www.instagram.com/rootstudios/" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faInstagram } />
                    </a>
                </div>

                <div className="c-site-footer-address">
                    <div className="c-site-footer-address-label">ROOT BKN</div>
                    <div>131 N. 14th Street</div>
                    <div>Brooklyn, NY 11249</div>
                    <div>Monday-Friday 8AM to 7PM</div>
                    <div><a href="tel:718.349.2740">718.349.2740</a></div>
                </div>

                <div className="c-site-footer-copyright">
                    ©{ new Date().getFullYear() } ROOT Studios.<br />
                    All Rights Reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
