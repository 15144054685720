export default {
    callAPI: "Expected callAPI to be a function.",
    formatData: "Expected formatData to be a function.",
    formatDataReturn: "Expected formatData to return an object.",
    meta: "Expected meta to be an object",
    options: "Expected each suffix to be a string",
    payload: "Expected payload to be an object",
    shouldCallAPI: "Expected shouldCallAPI to be a function.",
    type: "Expected type to be a string.",
    types: "Expected an array of three string types."
};
