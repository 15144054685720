import { STATUSES } from "./constants/constants";
import { formatCurrency } from "../utility/Currency";
import { intervalToBilling } from "../accounting";

export const getAccountState = store => store.account;
export const getAccountInfo = store => getAccountState(store)?.info;
export const getLoginState = store => store.auth.isLoggedIn;

export const getAccountFullName = store => {
    if (getLoginState(store)) {
        const { fname, lname } = getAccountInfo(store);

        return `${fname} ${lname}`.trim();
    } else {
        return null;
    }
};

export const getWishlists = store => getAccountState(store)?.wishlists ?? [];

export const getActiveWishlistId = store => getAccountState(store)?.activelist;

export const getWishlistState = store => store.wishlist;

export const getWishlist = store =>
    getWishlistState(store) ? getWishlistState(store).allIds : [];

export const getWishlistsByStatus = (store, status) => {
    const allWishlists = getWishlists(store);

    switch (status) {
        case STATUSES.ACTIVE:
            return allWishlists.filter(wishlist => wishlist.active);
        case STATUSES.PENDING:
            return allWishlists.filter(wishlist => wishlist.pending);
        case STATUSES.SUBMITTED:
            return allWishlists.filter(wishlist => wishlist.submitted);
        default:
            return allWishlists;
    }
};

export const getWishlistTax = store => getWishlistCosts(store).tax;

export const getWishlistSubtotal = store => getWishlistCosts(store).subtotal;

export const getWishlistTotal = store => getWishlistCosts(store).total;

export const getWishlistReplacementTotal = store => getWishlistCosts(store).replacementValue;

export const getWishlistProductById = (wishlist, id) => {
    return wishlist.products.find(p => p.id === id);
};

/**
 * Return a DateInterval object representing the rental period of a wishlist
 *
 * @param wishlist {{pickup_time: Date, dropoff_time: Date}}
 * @returns {{start: Date, end: Date}}
 */
export function wishlistToInterval({ pickup_time, dropoff_time }) {
    return {
        start: pickup_time,
        end: dropoff_time
    };
}

export const getDiscountedRentalPeriod = (wishlist) => {
    return intervalToBilling(wishlistToInterval(wishlist));
};

export const getWishlistCosts = wishlist => {
    const taxRate = wishlist.tax_exempt ? 0 : 0.08875;
    const billingDays = getDiscountedRentalPeriod(wishlist);

    let subtotal = 0,
        replacementValue = 0,
        tax = 0,
        total = 0;

    if (wishlist.items.length) {
        for (let item of wishlist.items) {
            let lineItemCost = item.cost * item.quantity;

            if (getWishlistProductById(wishlist, item.products_id).is_expendable === false) {
                lineItemCost *= billingDays;
            }
            subtotal += lineItemCost;
            replacementValue += item.replacement * item.quantity;
        }
        replacementValue = replacementValue.toFixed(2);
        tax = (subtotal * taxRate).toFixed(2);
        total = Number(subtotal) + Number(tax);
    }
    return {
        subtotal, replacementValue, tax, total
    };
};

export const getFormattedWishlistCosts = wishlist => {
    const costs = getWishlistCosts(wishlist);

    for (let key of Object.keys(costs)) { costs[key] = formatCurrency(costs[key]); }
    return costs;
};

export const getWishlistItemQuantity = (wishlist, productId) => {
    return wishlist.items.find(item => item.products_id === productId)?.quantity || 0;
};
