export const SET_UID = "SET_UID";
export const SET_APIKEY = "SET_APIKEY";
export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";
export const SET_WISHLIST = "SET_WISHLIST";
export const LOG_OUT = "LOG_OUT";

export const SET_ACCOUNT_INFO = "SET_ACCOUNT_INFO";
export const SET_LISTS = "SET_LISTS";
export const SET_ACTIVE_LIST = "GET_ACTIVE_LIST";
export const GET_LIST = "GET_LIST";
export const GET_ACTIVE_LIST_ITEMS = "GET_ACTIVE_LIST_ITEMS";
export const SET_LIST_STATUS = "SET_LIST_STATUS";
export const CREATE_LIST = "CREATE_LIST";
export const CREATE_LIST_PENDING = "CREATE_LIST_PENDING";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const CREATE_LIST_FAILURE = "CREATE_LIST_FAILURE";
export const UPDATE_LIST = "UPDATE_LIST";
export const DELETE_LIST = "DELETE_LIST";

export const ADD_LIST_ITEM = "ADD_LIST_ITEM";

export const SET_PICKUP_LOCATION = "SET_PICKUP_LOCATION";
export const SET_DROPOFF_LOCATION = "SET_DROPOFF_LOCATION";
export const ASSIGN_PO = "ASSIGN_PO";
export const ASSIGN_JOBREF = "ASSIGN_JOBREF";
export const SET_PICKUP_TIME = "SET_PICKUP_TIME";
export const SET_DROPOFF_TIME = "SET_DROPOFF_TIME";
export const SET_RENTAL_PERIOD = "SET_RENTAL_PERIOD";
export const SET_RENTAL_PERIOD_TEXT = "SET_RENTAL_PERIOD_TEXT";
export const SET_RENTAL_COST = "SET_RENTAL_COST";
export const SET_REPLACEMENT_COST = "SET_REPLACEMENT_COST";
export const SET_STATUS = "SET_STATUS";
export const SET_SUBSCRIBE = "SET_SUBSCRIBE";
export const SET_TAX_EXEMPT = "SET_TAX_EXEMPT";
export const SET_SUBTOTAL = "SET_SUBTOTAL";
export const SET_TAX = "SET_TAX";
export const SET_TOTAL = "SET_TOTAL";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const SET_WISHLIST_ID = "SET_WISHLIST_ID";
export const SET_REFERENCE = "SET_REFERENCE";
export const SET_NOTES = "SET_NOTES";

export const FETCH_INFO = "FETCH_INFO";
export const FETCH_INFO_PENDING = "FETCH_INFO_PENDING";
export const FETCH_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_INFO_FAILURE = "FETCH_INFO_FAILURE";

export const FETCH_ACTIVE_LIST_ITEMS = "FETCH_ACTIVE_LIST_ITEMS";
export const FETCH_ACTIVE_LIST_ITEMS_PENDING = "FETCH_ACTIVE_LIST_ITEMS_PENDING";
export const FETCH_ACTIVE_LIST_ITEMS_SUCCESS = "FETCH_ACTIVE_LIST_ITEMS_SUCCESS";
export const FETCH_ACTIVE_LIST_ITEMS_FAILURE = "FETCH_ACTIVE_LIST_ITEMS_FAILURE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_PENDING = "ADD_PRODUCT_PENDING";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";

export const API_ADD_LIST_ITEM = "API_ADD_LIST_ITEM";
export const API_ADD_LIST_ITEM_PENDING = "API_ADD_LIST_ITEM_PENDING";
export const API_ADD_LIST_ITEM_SUCCESS = "API_ADD_LIST_ITEM_SUCCESS";
export const API_ADD_LIST_ITEM_FAILURE = "API_ADD_LIST_ITEM_FAILURE";

export const API_REMOVE_LIST_ITEM = "API_REMOVE_LIST_ITEM";
export const API_REMOVE_LIST_ITEM_PENDING = "API_REMOVE_LIST_ITEM_PENDING";
export const API_REMOVE_LIST_ITEM_SUCCESS = "API_REMOVE_LIST_ITEM_SUCCESS";
export const API_REMOVE_LIST_ITEM_FAILURE = "API_REMOVE_LIST_ITEM_FAILURE";

export const LOCATION_LOOKUP = "LOCATION_LOOKUP";
export const LOCATION_LOOKUP_PENDING = "LOCATION_LOOKUP_PENDING";
export const LOCATION_LOOKUP_SUCCESS = "LOCATION_LOOKUP_SUCCESS";
export const LOCATION_LOOKUP_FAILURE = "LOCATION_LOOKUP_FAILURE";

export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const SUBMIT_ORDER_PENDING = "SUBMIT_ORDER_PENDING";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const SUBMIT_ORDER_FAILURE = "SUBMIT_ORDER_FAILURE";

export const ACTIVATE_LIST = "ACTIVATE_LIST"; // (listId)
export const ACTIVATE_LIST_PENDING = "ACTIVATE_LIST_PENDING"; // (listId)
export const ACTIVATE_LIST_SUCCESS = "ACTIVATE_LIST_SUCCESS"; // (listId, listData)
export const ACTIVATE_LIST_FAILURE = "ACTIVATE_LIST_FAILURE"; // (listId, reason)

export const REQUIRE_SYNC = "REQUIRE_SYNC";
export const HYDRATE = "HYDRATE";
export const RESTORE_VALID_SCHEMA = "RESTORE_VALID_SCHEMA";
