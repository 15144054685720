import * as types from "../constants/ActionTypes";
import axios from "axios";
import { getAccountInfo, getLoginState, getWishlists, getWishlistState } from "../selectors";
import { isValid, isDate } from "date-fns";
import { HYDRATE, RESTORE_VALID_SCHEMA } from "../constants/ActionTypes";

// App Initialization (Asynchronous)
export function init() {
    return (dispatch, getState) => {
        // This action will always return a promise.
        return Promise.resolve()
            .then(() => {
                // 1. Clear out some transient state that might end up in a local snapshot
                return dispatch(clearSyncState());
            })
            .then(() => {
                // 2. Is there login data? If yes, go fetch account data
                if (getLoginState(getState())) {
                    return dispatch(fetchInfo()).then(() => true).catch(error => {
                        // In case of fetch error that's not recoverable, log out and reset session
                        if (error.response.status >= 400) {
                            dispatch(logOut());
                            dispatch(setFlashMessage("An error occurred when fetching account data. Please try logging in again."));
                            console.error(error);
                            console.warn("Error fetching account data; session reset, error data above;");
                        }

                        return false;
                    });
                } else {
                    return false;
                }
            })
            .then(() => {
                // 3. Emit an action to indicate app is ready
                return dispatch({ type: "INIT_SUCCESS" });
            });
    };
}

// To be dispatched when a loaded schema mismatch is detected
export function restoreValidSchema() {
    return { type: RESTORE_VALID_SCHEMA };
}

// To be dispatched before initialization
export function hydrate() {
    return { type: HYDRATE };
}

// General App Actions
export function setFlashMessage(message, forPath = null) {
    return {
        type: "SET_FLASH_MESSAGE",
        message,
        messageType: "info",
        path: forPath
    };
}

export function clearFlashMessage() {
    return { type: "CLEAR_FLASH_MESSAGE" };
}

// Auth
export function setUid(uid) {
    return {
        type: types.SET_UID,
        uid
    };
}

export function setApikey(apikey) {
    return {
        type: types.SET_APIKEY,
        apikey
    };
}

export function setIsLoggedIn(isLoggedIn) {
    return {
        type: types.SET_IS_LOGGED_IN,
        isLoggedIn
    };
}

export function submitLogin(loginData, onSuccess = () => {}) {
    return (dispatch, getState) => {
        return axios.post(process.env.REACT_APP_API_URL + "login", loginData, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res => {
            let u = res.data.substr(0, res.data.indexOf(":")),
                a = res.data.substr(res.data.indexOf(":") + 1);

            // Assign the auth states
            dispatch(setUid(u));
            dispatch(setApikey(a));
            dispatch(setIsLoggedIn(1));

            // Fetch account data, then mark the Wishlist state as tax_exempt by default
            // if the account is also a tax-exempt account
            dispatch(fetchInfo()).then(() => {
                dispatch(setTaxExempt(getAccountInfo(getState()).tax_exempt));
                return true;
            });

            // Invoke a synchronous success hook; useful for redirecting on login
            onSuccess(getState);
        });
    };
}

export function submitRegistration(regData) {
    return (dispatch, getState) => {
        return axios.post(process.env.REACT_APP_API_URL + "register", { ...regData, frontend: true }, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res => {
            // On success, extract the uid and apiKey
            let userId = res.data.substr(0, res.data.indexOf(":")),
                apiKey = res.data.substr(res.data.indexOf(":") + 1);

            // Assign the auth states
            dispatch(setUid(userId));
            dispatch(setApikey(apiKey));
            dispatch(setIsLoggedIn(1));
        });
    };
}

export function submitOrder(createAccount, orderData, wishlist) {
    return (dispatch, getState) => {
        return Promise.resolve()
            .then(() => {
                if (createAccount && !getState().auth.isLoggedIn) {
                    return dispatch(submitRegistration({
                        fname: orderData.fname,
                        lname: orderData.lname,
                        email: orderData.email,
                        phone: orderData.phone,
                        company: orderData.company,
                        password: orderData.password,
                        password_confirmation: orderData.password_confirmation,
                        tax_exempt: getState().wishlist.tax_exempt,
                        frontend: true
                    }));
                }
            })
            .then(() => {
                if (getState().auth.isLoggedIn) {
                    return axios.post(`${process.env.REACT_APP_API_URL}api/wishlist/submit-order`, {
                        fname: orderData.fname,
                        lname: orderData.lname,
                        email: orderData.email,
                        phone: orderData.phone,
                        company: orderData.company,
                        wishlist: getState().wishlist
                    }).then((response) => {
                        console.log("success submitting order");
                        console.log(response);
                        dispatch(clearListItems());

                        return response.data;
                    });
                } else {
                    return axios.post(`${process.env.REACT_APP_API_URL}api/wishlist/submit-guest-order`, {
                        fname: orderData.fname,
                        lname: orderData.lname,
                        email: orderData.email,
                        phone: orderData.phone,
                        company: orderData.company,
                        wishlist: getState().wishlist
                    }).then((response) => {
                        console.log("success submitting order");
                        console.log(response);
                        dispatch(clearListItems());

                        return response.data;
                    });
                }
            });
    };
}

export function setWishlist(wishlist) {
    return {
        type: types.SET_WISHLIST,
        wishlist
    };
}

export function logOut() {
    return { type: types.LOG_OUT };
}

// Account
export function setAccountInfo(info) {
    return {
        type: types.SET_ACCOUNT_INFO,
        info
    };
}

export function setLists(wishlists) {
    return {
        type: types.SET_LISTS,
        wishlists
    };
}

export function setActiveList(wishlist) {
    return {
        type: types.SET_ACTIVE_LIST,
        wishlist
    };
}

export function saveCurrentWishlist() {
    return (dispatch, getState) => {
        // Create a promise to wait on if async prep needs to happen
        let getTargetListId = Promise.resolve();

        // 1. If not logged in or wishlist is empty: skip this, because we can't save anything
        if (!getLoginState(getState())) {
            return Promise.reject("Not logged in"); // Always return a promise, for consistency
        }

        if (getWishlistState(getState()).items.length === 0) {
            return Promise.reject("Nothing to save");
        }

        // 2. If the wishlist data is not known in the DB: create a wishlist record to hold data
        if (getWishlistState(getState()).id ?? -1 > 0) {
            getTargetListId = dispatch(createList()).then(() => {
                // Fetch the largest wishlist ID from the state, which is assumed to be the recently added list
                return getWishlists(getState()).reduce((max, current) => current.id > max.id ? current : max)?.id;
            });
        } else {
            // If a wishlist ID is already known, use that as the target
            getTargetListId = Promise.resolve(getWishlistState(getState()).id);
        }

        return getTargetListId.then((targetListID) => {
            // 3. Assign the target ID to the wishlist state
            dispatch(setWishlistId(targetListID));

            // 4. Sync the current wishlist data to save it
            return dispatch(syncWishlist());
        });
    };
}

export function createList() {
    return {
        typePrefix: types.CREATE_LIST,
        shouldCallAPI: state => getLoginState(state),
        callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/create-list", {}, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }),
        formatData: ({ data }) => ({ wishlist: data })
    };
}

export function getList(wishlist) {
    return { type: types.GET_LIST, wishlist };
}

export function getActiveListItems(items) {
    return { type: types.GET_ACTIVE_LIST_ITEMS, items };
}

export function setListStatus(wishlist) {
    return { type: types.SET_LIST_STATUS, wishlist };
}

export function updateList(wishlist) {
    return { type: types.UPDATE_LIST, wishlist };
}

export function deleteList(wishlist) {
    return { type: types.DELETE_LIST, wishlist };
}

// Wishlist
export function clearSyncState() {
    return { type: "CLEAR_SYNC_STATE" };
}

export function clearListItems() {
    return { type: "CLEAR_WISHLIST_ITEMS" };
}

/**
 * Request to sync a wishlist with the backend. Allows only one concurrent request.
 * If multiple sync requests are dispatched before the first settles, it will instead
 * queue another request that will be triggered after the first resolves.
 * @returns {(function(*, *): (Promise<*>))|*}
 */
export function syncWishlist() {
    return (dispatch, getState) => {
        // Check if a sync is in progress; if it is, skip the API call, but mark that a resync is required.
        if (getState().wishlist.isSyncing) {
            return Promise.resolve(dispatch(requireSync(true)));
        }

        // A quick freshness check; if the server has newer data than us, download that instead
        return axios.post(process.env.REACT_APP_API_URL + "api/wishlist/get-list", { list_id: getWishlistState(getState()).id }).then((response) => {
            // Get the Last-Modified header as a Unix timestamp
            const lastModified = Math.floor(new Date(response.headers["Last-Modified"]).getTime() / 1000);
            const localModified = Math.floor(new Date(getWishlistState(getState()).updated_at).getTime() / 1000);

            if (lastModified > localModified) {
                // Replace our local data with that of the server

            }

            // If this is the initial sync request, start an API call
            return dispatch({
                typePrefix: "SYNC_WISHLIST",
                shouldCallAPI: state => {
                // If the user is logged in and the current wishlist has a DB identifier, sync its data on demand
                    if (getLoginState(state) === false || !getWishlistState(state).id || getWishlistState(state).id <= 0) {
                        return false;
                    }

                    // Don't create duplicate sync requests
                    if (state.wishlist.isSyncing) {
                        return false;
                    }

                    return true;
                },
                callAPI: (state) => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/update-list", {
                    list_id: getWishlistState(state).id,
                    values: getWishlistState(state)
                })
            });
        })
            .then(() => {
                // After the API call completes, check if another sync request came in while the current one
                // was settling. If needed, dispatch this action again to square up things that got stale
                // during the previous request lifecycle. Clear the resync bit to reset things correctly.
                if (getState().wishlist.require_sync) {
                    dispatch(requireSync(false));
                    return dispatch(syncWishlist());
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function setPickupTime(pickup_time) {
    // The input must be a Date object or null
    if (pickup_time !== null && (!isDate(pickup_time) || !isValid(pickup_time))) {
        throw new Error(`${pickup_time} is not a valid Date`);
    }

    return {
        type: types.SET_PICKUP_TIME,
        pickup_time
    };
}

export function setDropoffTime(dropoff_time) {
    // The input must be a Date object or null
    if (dropoff_time !== null && (!isDate(dropoff_time) || !isValid(dropoff_time))) {
        throw new Error(`${dropoff_time} is not a valid Date`);
    }

    return {
        type: types.SET_DROPOFF_TIME,
        dropoff_time
    };
}

export function setRentalPeriod(rental_period) {
    return {
        type: types.SET_RENTAL_PERIOD,
        rental_period
    };
}

export function setRentalPeriodText(rental_period_text) {
    return {
        type: types.SET_RENTAL_PERIOD_TEXT,
        rental_period_text
    };
}

export function setPickupLocation(pickup_location) {
    return {
        type: types.SET_PICKUP_LOCATION,
        pickup_location: {
            address: pickup_location.formatted_address,
            components: pickup_location.components,
            geometry: pickup_location.geometry
        }
    };
}

export function setDropoffLocation(dropoff_location) {
    return {
        type: types.SET_DROPOFF_LOCATION,
        dropoff_location: {
            address: dropoff_location.formatted_address,
            components: dropoff_location.components,
            geometry: dropoff_location.geometry
        }
    };
}

export function assignPo(po) {
    return {
        type: types.ASSIGN_PO,
        po
    };
}

export function assignJobref(jobref) {
    return {
        type: types.ASSIGN_JOBREF,
        jobref
    };
}

export function addListItem(id) {
    return { type: types.ADD_LIST_ITEM, id };
}

export function removeProduct(id) {
    return { type: types.REMOVE_PRODUCT, id };
}

export function setRentalCost(rental_cost) {
    return { type: types.SET_RENTAL_COST, rental_cost };
}

export function setReplacementCost(replacement_cost) {
    return { type: types.SET_REPLACEMENT_COST, replacement_cost };
}

export function setStatus(status) {
    return { type: types.SET_STATUS, status };
}

export function setSubscribe(subscribe) {
    return { type: types.SET_SUBSCRIBE, subscribe };
}

export function setTaxExempt(tax_exempt) {
    return { type: types.SET_TAX_EXEMPT, tax_exempt };
}

export function setSubtotal(subtotal) {
    return { type: types.SET_SUBTOTAL, subtotal };
}

export function setTax(tax) {
    return { type: types.SET_TAX, tax };
}

export function setTotal(total) {
    return { type: types.SET_TOTAL, total };
}

export function updateQuantity(product) {
    return {
        type: types.UPDATE_QUANTITY,
        quantity: product.quantity,
        id: product.id
    };
}

export function setWishlistId(id) {
    return {
        type: types.SET_WISHLIST_ID,
        id
    };
}

export function setReference(reference) {
    return {
        type: types.SET_REFERENCE,
        reference
    };
}

export function setNotes(notes) {
    return {
        type: types.SET_NOTES,
        notes
    };
}

export function requireSync(bool) {
    return { type: types.REQUIRE_SYNC, bool };
}

export function shouldUpdateDB(state) {
    const wishlist = state.wishlist;

    if (!wishlist) {
        return false;
    } else if (wishlist.isFetching) {
        return false;
    } else {
        return wishlist.requireSync;
    }
}

// API Calls
export function fetchInfo() {
    return {
        typePrefix: types.FETCH_INFO,
        shouldCallAPI: (state) => state.auth.isLoggedIn === 1,
        callAPI: (state) => axios.get(process.env.REACT_APP_API_URL + "api/user/me/details", {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }),
        formatData: (res) => ({
            info: res.data.info,
            wishlists: res.data.wishlists,
            activelist: res.data.active_wishlist_id
        })
    };
}

export function fetchActiveWishlistItems() {
    return {
        typePrefix: types.FETCH_ACTIVE_LIST_ITEMS,
        shouldCallAPI: (state) => state.auth.isLoggedIn === 1,
        callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/get-active-list", {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(res => res.data)
            .catch(error => console.log(error)),
        formatData: (res) => ({
            items: res.list.items,
            products: res.products,
            id: res.list.id,
            reference: res.list.reference,
            po: res.list.po,
            note: res.list.note,
            pickup_location: res.list.pickup_location,
            dropoff_location: res.list.dropoff_location,
            jobref: res.list.jobref,
            pickup_time: res.list.pickup_time,
            dropoff_time: res.list.dropoff_time,
            rental_period: res.list.rental_period,
            status: res.list.status,
            tax_exempt: res.list.tax_exempt,
            rental_cost: res.total_rental_cost,
            replacement_cost: res.total_replacement_cost
        })
    };
}

export function apiAddListItem(uid, apikey, listId, productId) {
    return {
        typePrefix: types.API_ADD_LIST_ITEM,
        shouldCallAPI: (state) => state.auth.isLoggedIn === 1,
        callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/add-list-item?api_token=" + apikey, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            users_id: uid,
            api_key: apikey,
            list_id: listId,
            product_id: productId
        })
            .then(res => res.data)
            .catch(error => console.log(error)),
        formatData: (res) => ({
            item: res
        })
    };
}

export function apiRemoveListItem(uid, apikey, listId, productId) {
    return {
        typePrefix: types.API_REMOVE_LIST_ITEM,
        shouldCallAPI: (state) => state.auth.isLoggedIn === 1,
        callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/remove-list-item?api_token=" + apikey, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            users_id: uid,
            api_key: apikey,
            list_id: listId,
            product_id: productId
        })
            .then(res => res.data)
            .catch(error => console.log(error)),
        formatData: (res) => ({
            item: res
        })
    };
}

export function addProduct(productId) {
    return dispatch => {
        // Download product data and add an item to the local wishlist
        return dispatch({
            typePrefix: types.ADD_PRODUCT,
            callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/product/get-product", {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                id: productId
            })
                .then(res => {
                    return res.data;
                })
                .catch(error => console.log(error)),
            formatData: (res) => ({
                product: res
            })
        });

        // // At the same time, send a request to persist the product in the active wishlist
        // dispatch({
        //     typePrefix: "PERSIST_PRODUCT",
        //     shouldCallAPI: (state) => getLoginState(state),
        //     callAPI: (state) => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/add-list-item", {
        //         list_id: getActiveWishlistId(state),
        //         product_id: productId
        //     })
        // });
    };
}

export function directAddProduct(productData) {
    return {
        type: types.ADD_PRODUCT_SUCCESS,
        product: productData
    };
}

export function lookup(address) {
    return {
        typePrefix: types.LOCATION_LOOKUP,
        shouldCallAPI: (state) => true,
        callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/location-lookup", {
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            address: address
        })
            .then(res => {
                if (typeof res === "undefined") {
                    console.log("undef");
                }
                return res;
            })
            .catch(error => console.log(error)),
        formatData: (res) => ({
            locations: res
        })
    };
}

/**
 * Request that the active list be updated in the database, and receive its data in full to sync.
 * @param id
 * @param history
 * @returns {{formatData: (function(*): *), typePrefix: string, callAPI: (function(): Promise<AxiosResponse<any>>)}}
 */
export function activateList(id, history) {
    return dispatch => {
        return dispatch({
            typePrefix: types.ACTIVATE_LIST,
            callAPI: () => axios.post(process.env.REACT_APP_API_URL + "api/wishlist/activate-list", {
                list_id: id
            }),
            formatData: (res) => res.data
        })
            .then(() => {
                dispatch(fetchActiveWishlistItems());
            });
    };
}
