import * as D from "date-fns";

/**
 * Get the number of calendar days that represent a rental period, including partial days
 *
 * @param interval {{start: Date, end: Date}}
 * @returns {number}
 */
export function intervalToRentalPeriod({ start, end }) {
    return D.differenceInCalendarDays(end, start) + 1;
}

/**
 * Convert a date interval to a billable day count
 *
 * This function operates under the assumption that the interval is already validated,
 * and it does not start or end on a weekend.
 *
 * For short rentals (<=3 days), a special Weekend rate can apply if any of the days
 * are a Saturday or Sunday. The minimum rental for this period is 3 days instead of
 * the 2 that would be calculated normally.
 *
 * Note this code does not correctly handle cases where rentals start or end on weekends,
 * as these are not considered business hours.
 *
 * @param interval {{start: Date, end: Date}}
 * @returns {number}
 */
export function intervalToBilling(interval) {
    const WEEKLY_RATE = 3;

    const { start, end } = interval;
    const calendarDays = intervalToRentalPeriod(interval);

    // Helpers that return the cutoff times for a given date
    const pickupTimeOnDay = date => D.set(new Date(date), { hours: 15, minutes: 0, seconds: 0, milliseconds: 0 });
    const dropoffTimeOnDay = date => D.set(new Date(date), { hours: 10, minutes: 0, seconds: 0, milliseconds: 0 });

    if (calendarDays <= 2) {
        return 1;
    }

    // Check for an over-weekend rental condition
    const isWeekendRental = calendarDays <= 4 && D.eachDayOfInterval(interval).some(D.isWeekend);

    // Default billing basis is full 24-hour calendar days of the rental
    let billingDays = calendarDays - 2;

    // In case of a rental over a weekend, the billing basis starts at 3 days
    if (isWeekendRental) {
        billingDays = 3;
    }

    // If the pickup time is before the afternoon cutoff, bill for that day
    if (D.isBefore(start, pickupTimeOnDay(start))) {
        billingDays++;
    }

    // If the dropoff time is after the morning cutoff, bill for that day as well
    if (D.isAfter(end, dropoffTimeOnDay(end))) {
        billingDays++;
    }

    if (billingDays > WEEKLY_RATE) {
        if (Math.round(billingDays / 7 % 1)) {
            // If the billing / 7 would round up, bill a full additional week.
            return Math.ceil(billingDays / 7) * WEEKLY_RATE;
        } else {
            // If the billing / 7 would round down, bill for the partial week.
            return Math.floor(billingDays / 7) * WEEKLY_RATE // Weekly discounts
                + billingDays % 7; // Extra days
        }
    } else {
        return billingDays;
    }
}
