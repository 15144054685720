/**
 * Current major schema version
 *
 * Increment this when making backward-incompatible changes to the application state schema.
 * When a client detects a schema change, the locally stored app data will be cleared.
 *
 * @type {string}
 */
export const SCHEMA_VERSION = "3";

export const STATUSES = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    SUBMITTED: "SUBMITTED"
};
