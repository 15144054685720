import {
    ADD_PRODUCT_PENDING,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAILURE,
    REMOVE_PRODUCT,
    FETCH_ACTIVE_LIST_ITEMS_PENDING,
    FETCH_ACTIVE_LIST_ITEMS_SUCCESS,
    FETCH_ACTIVE_LIST_ITEMS_FAILURE,
    API_ADD_LIST_ITEM_PENDING,
    API_ADD_LIST_ITEM_SUCCESS,
    API_ADD_LIST_ITEM_FAILURE,
    API_REMOVE_LIST_ITEM_PENDING,
    API_REMOVE_LIST_ITEM_SUCCESS,
    API_REMOVE_LIST_ITEM_FAILURE,
    ADD_LIST_ITEM,
    SET_PICKUP_TIME,
    SET_DROPOFF_TIME,
    SET_RENTAL_PERIOD,
    SET_RENTAL_PERIOD_TEXT,
    SET_PICKUP_LOCATION,
    SET_DROPOFF_LOCATION,
    SET_RENTAL_COST,
    SET_REPLACEMENT_COST,
    ASSIGN_PO,
    ASSIGN_JOBREF,
    SET_SUBSCRIBE,
    SET_TAX_EXEMPT,
    SET_SUBTOTAL,
    SET_TAX,
    SET_TOTAL,
    SET_WISHLIST,
    UPDATE_QUANTITY,
    SET_WISHLIST_ID,
    SET_REFERENCE,
    LOCATION_LOOKUP_PENDING,
    LOCATION_LOOKUP_SUCCESS,
    LOCATION_LOOKUP_FAILURE,
    REQUIRE_SYNC,
    LOG_OUT,
    HYDRATE, SET_NOTES
} from "../constants/ActionTypes";
import { updateObject, updateItemInArray } from "./helpers";
import { isDate } from "date-fns";
import { SCHEMA_VERSION } from "../constants/constants";

const initialState = {
    schema_version: SCHEMA_VERSION,
    items: [],
    products: [],
    id: -1,
    reference: "",
    po: "",
    notes: null,
    pickup_location: null,
    dropoff_location: null,
    jobref: "",
    pickup_time: null,
    dropoff_time: null,
    rental_period: 1,
    rental_period_text: "Set Rental Period",
    rental_cost: 0,
    replacement_cost: 0,
    status: -1,
    status_text: "pending",
    subscribe: false,
    tax_exempt: false,
    subtotal: 0,
    tax: 0,
    total: 0,
    active: true,
    isFetching: false,
    isSyncing: false,
    activeSyncPromise: Promise.resolve(),
    error: null,
    require_sync: false,
    updated_at: new Date(0).toISOString()
};

function setWishlist(state, action) {
    return updateObject(state, action.wishlist);
}

function setPickupTime(state, action) {
    return updateObject(state, { pickup_time: action.pickup_time });
}

function setDropoffTime(state, action) {
    return updateObject(state, { dropoff_time: action.dropoff_time });
}

function setRentalPeriod(state, action) {
    return updateObject(state, { rental_period: action.rental_period });
}

function setRentalPeriodText(state, action) {
    return updateObject(state, { rental_period_text: action.rental_period_text });
}

function setPickupLocation(state, action) {
    return updateObject(state, { pickup_location: action.pickup_location });
}

function setDropoffLocation(state, action) {
    return updateObject(state, { dropoff_location: action.dropoff_location });
}

function assignPo(state, action) {
    return updateObject(state, { po: action.po });
}

function assignJobref(state, action) {
    return updateObject(state, { jobref: action.jobref });
}

function setSubscribe(state, action) {
    return updateObject(state, { subscribe: action.subscribe });
}

function setTaxExempt(state, action) {
    return updateObject(state, { tax_exempt: action.tax_exempt });
}

function setSubtotal(state, action) {
    return updateObject(state, { subtotal: action.subtotal });
}

function setTax(state, action) {
    return updateObject(state, { tax: action.tax });
}

function setTotal(state, action) {
    return updateObject(state, { total: action.total });
}

function setRentalCost(state, action) {
    return updateObject(state, { rental_cost: action.rental_cost });
}

function setReplacementCost(state, action) {
    return updateObject(state, { replacement_cost: action.replacement_cost });
}

function setWishlistId(state, action) {
    return updateObject(state, { id: action.id });
}

function setReference(state, action) {
    return updateObject(state, { reference: action.reference });
}

function setNotes(state, action) {
    return updateObject(state, { notes: action.notes || null });
}

function addProduct(state, action) {
    /* Check to see if there is an object in the wishlist.items array
    * whose products_id matches the productId of the item being added.
    */
    let itemAlreadyInWishlist = false;

    for (let item of state.items) {
        if (item.products_id === action.product.id) {
            itemAlreadyInWishlist = true;
            break;
        }
    }

    if (!itemAlreadyInWishlist) {
        return Object.assign({}, state, {
            items: [
                ...state.items,
                {
                    products_id: action.product.id,
                    quantity: 1,
                    cost: action.product.rental_cost / 100,
                    replacement: action.product.replacement_cost / 100,
                    rental_cost: action.product.rental_cost,
                    replacement_cost: action.product.replacement_cost,
                    sku: action.product.sku,
                    title: action.product.title
                }
            ],
            products: [
                ...state.products,
                action.product
            ]
        });
    } else {
        for (let item of state.items) {
            if (item.products_id === action.product.id) {
                let newItems = updateItemInArray(state.items, action.product.id, item => {
                    return updateObject(item, { quantity: item.quantity + 1 });
                });

                return updateObject(state, { items: newItems });
            }
        }

        return state;
    }
}

function removeProduct(state, action) {
    return updateObject(state, {
        items: state.items.filter(item => item.products_id !== action.id),
        products: state.products.filter(product => product.id !== action.id)
    });
}

function updateQuantity(state, action) {
    for (let item of state.items) {
        if (item.products_id === action.id) {
            let newItems = updateItemInArray(state.items, action.id, item => {
                return updateObject(item, { quantity: action.quantity });
            });

            return updateObject(state, { items: newItems });
        }
    }
    return state;
}

function lookup(state, action) {
    console.log(action.locations);
    return state;
}

function requireSync(state, action) {
    return updateObject(state, { require_sync: action.bool });
}

const wishlist = (state = initialState, action) => {
    switch (action.type) {
        case SET_WISHLIST:
            return setWishlist(state, action);
        case FETCH_ACTIVE_LIST_ITEMS_PENDING:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });
        case FETCH_ACTIVE_LIST_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.items,
                products: action.products,
                id: action.id,
                reference: action.reference,
                po: action.po,
                note: action.note,
                pickup_location: action.pickup_location,
                dropoff_location: action.dropoff_location,
                jobref: action.jobref,
                pickup_time: action.pickup_time,
                dropoff_time: action.dropoff_time,
                rental_period: action.rental_period,
                status: action.status,
                tax_exempt: action.tax_exempt,
                subtotal: action.subtotal,
                tax: action.tax,
                total: action.total,
                rental_cost: action.rental_cost,
                replacement_cost: action.replacement_cost
            });
        case FETCH_ACTIVE_LIST_ITEMS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case SET_RENTAL_COST:
            return setRentalCost(state, action);
        case SET_REPLACEMENT_COST:
            return setReplacementCost(state, action);
        case ADD_LIST_ITEM:
            return;
        case API_ADD_LIST_ITEM_PENDING:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });
        case API_ADD_LIST_ITEM_SUCCESS:
            return;
        case API_ADD_LIST_ITEM_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case API_REMOVE_LIST_ITEM_PENDING:
            return;
        case API_REMOVE_LIST_ITEM_SUCCESS:
            return;
        case API_REMOVE_LIST_ITEM_FAILURE:
            return;
        case SET_PICKUP_TIME:
            return setPickupTime(state, action);
        case SET_DROPOFF_TIME:
            return setDropoffTime(state, action);
        case SET_RENTAL_PERIOD:
            return setRentalPeriod(state, action);
        case SET_RENTAL_PERIOD_TEXT:
            console.warn("DEPRECATED: Action SET_RENTAL_PERIOD_TEXT should not be used. Rental text is now a computed property.");
            return setRentalPeriodText(state, action);
        case SET_PICKUP_LOCATION:
            return setPickupLocation(state, action);
        case SET_DROPOFF_LOCATION:
            return setDropoffLocation(state, action);
        case ASSIGN_PO:
            return assignPo(state, action);
        case ASSIGN_JOBREF:
            return assignJobref(state, action);
        case SET_SUBSCRIBE:
            return setSubscribe(state, action);
        case SET_TAX_EXEMPT:
            return setTaxExempt(state, action);
        case SET_SUBTOTAL:
            return setSubtotal(state, action);
        case SET_TAX:
            return setTax(state, action);
        case SET_TOTAL:
            return setTotal(state, action);
        case UPDATE_QUANTITY:
            return updateQuantity(state, action);
        case SET_WISHLIST_ID:
            return setWishlistId(state, action);
        case SET_REFERENCE:
            return setReference(state, action);
        case SET_NOTES:
            return setNotes(state, action);
        case ADD_PRODUCT_PENDING:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });
        case ADD_PRODUCT_SUCCESS:
            return addProduct(state, action);
        case ADD_PRODUCT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case REMOVE_PRODUCT:
            return removeProduct(state, action);
        case LOCATION_LOOKUP_PENDING:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });
        case LOCATION_LOOKUP_SUCCESS:
            return lookup(state, action);
        case LOCATION_LOOKUP_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case LOG_OUT:
            // Unlink the wishlist data from a user account but retain items and info
            return Object.assign({}, state, {
                id: -1,
                status: -1,
                status_text: "pending",
                error: null,
                isFetching: false,
                isSyncing: false,
                require_sync: false,
                // Unlink wishlist items from their database identifiers
                items: state.items.map(item => {
                    delete item.id;
                    delete item.wishlists_id;
                    return item;
                })
            });
        case REQUIRE_SYNC:
            return requireSync(state, action);
        case "SYNC_WISHLIST":
        case "SYNC_WISHLIST_PENDING":
            return {
                ...state,
                isSyncing: true
            };
        case "SYNC_WISHLIST_SUCCESS":
            return {
                ...state,
                isSyncing: false
            };
        // If a sync failed, clear the resync bit so we don't attempt again
        case "SYNC_WISHLIST_FAILURE":
            return {
                ...requireSync(state, { bool: false }),
                isSyncing: false,
                error: action.error
            };
        case "CLEAR_SYNC_STATE":
            return {
                ...state,
                isSyncing: false,
                isFetching: false,
                require_sync: false,
                activeSyncPromise: Promise.resolve(),
                error: null
            };
        case "CLEAR_WISHLIST_ITEMS":
            return {
                ...state,
                subtotal: 0,
                tax: 0,
                total: 0,
                rental_cost: 0,
                replacement_cost: 0,
                items: [],
                products: []
            };

        // Date objects don't get restored from snapshots correctly.
        // Date() objects and null are allowed as values. Anything else must be parsed into a date.
        case HYDRATE: {
            try {
                return {
                    ...state,
                    pickup_time: state.pickup_time === null || isDate(state.pickup_time) ? state.pickup_time : new Date(state.pickup_time),
                    dropoff_time: state.dropoff_time === null || isDate(state.dropoff_time) ? state.dropoff_time : new Date(state.dropoff_time)
                };
            } catch (e) {
                console.error("Error when hydrating wishlist reducer", e);
                return {
                    ...state,
                    pickup_time: null,
                    dropoff_time: null
                };
            }
        }

        default:
            return state;
    }
};

export default wishlist;
