import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxCookiesMiddleware from "redux-cookies-middleware";
import getStateFromCookies from "redux-cookies-middleware";
import { load, save, clear } from "redux-localstorage-simple";
import callTheAPI from "./middlewares/callAPI";
import rootReducer from "./reducers/index";
import Cookies from "js-cookie";
import { hydrate, init, restoreValidSchema, setWishlist } from "./actions";
import axios from "axios";
import $ from "jquery";
import { SCHEMA_VERSION } from "./constants/constants";
import Bugsnag from "@bugsnag/js";

// Name the cookies that will store state
const paths = {
    "auth.apikey": {
        name: "apikey"
    },
    "auth.uid": {
        name: "uid"
    },
    "auth.isLoggedIn": {
        name: "isLoggedIn"
    }
};

// Read stored data in cookies and merge with initial state of the combined reducer
rootReducer.initialState = getStateFromCookies(rootReducer.initialState, paths);

// Create store with data stored in cookies merged with initial state
export default function configureStore(initialState) {
    const middlewares = [
        thunk,
        reduxCookiesMiddleware(paths),
        save({ states: [ "wishlist" ] }),
        callTheAPI.withOptions({
            pendingSuffix: "_PENDING",
            successSuffix: "_SUCCESS",
            failureSuffix: "_FAILURE"
        })
    ];

    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [ middlewareEnhancer ];
    const composedEnhancers = composeWithDevTools(...enhancers);

    let store = createStore(
        rootReducer,
        load({
            states: [ "wishlist" ],
            preloadedState: initialState
        }),
        composedEnhancers
    );

    // Locate wishlist data that may reside in cookies and migrate it to local storage
    let preloadedWishlistData = undefined;

    if (Cookies.getJSON("wishlist")) {
        preloadedWishlistData = Cookies.getJSON("wishlist");
        store.dispatch(setWishlist(preloadedWishlistData));
        Cookies.remove("wishlist");
    }

    // Check for breaking schema changes
    const loadedSchemaVersion = store.getState()?.wishlist?.schema_version;

    // Reset state and delete local storage data if the schema version doesn't match
    if (loadedSchemaVersion !== SCHEMA_VERSION) {
        const error = `Schema mismatch detected! Expected ${SCHEMA_VERSION}, got ${loadedSchemaVersion}`;

        console.info(error);
        Bugsnag.notify(error);
        clear();
        store.dispatch(restoreValidSchema());
    }

    // If there is some auth data locally, set up our AJAX clients to use it
    const apikey = store.getState().auth.apikey;

    if (apikey) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${apikey}`;
        $.ajaxSetup({
            headers: {
                Authorization: `Bearer ${apikey}`
            }
        });
    }

    // Kick off the store initialization process
    store.dispatch(hydrate());
    store.dispatch(init());

    return store;
}
