import {
    FETCH_INFO_PENDING,
    FETCH_INFO_SUCCESS,
    FETCH_INFO_FAILURE,
    SET_ACCOUNT_INFO,
    SET_LISTS,
    SET_ACTIVE_LIST,
    CREATE_LIST_SUCCESS,
    ACTIVATE_LIST_SUCCESS,
    LOG_OUT
} from "../constants/ActionTypes";
import { updateObject } from "./helpers";

const initialState = {
    info: {
        fname: "",
        mname: "",
        lname: "",
        email: "",
        password: "",
        phone: "",
        company: "",
        address_line_one: "",
        address_line_two: "",
        city: "",
        state: "",
        zip: "",
        tax_exempt: false,
        subscribed: false
    },
    wishlists: [],
    activelist: -1,
    isFetching: false,
    error: null
};

function setAccountInfo(state, action) {
    return updateObject(state, { info: action.info });
}

function setLists(state, action) {
    return updateObject(state, { wishlists: action.wishlists });
}

function setActiveList(state, action) {
    return updateObject(state, { activelist: action.wishlist });
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case LOG_OUT:
            return Object.assign({}, state, initialState);
        case FETCH_INFO_PENDING:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });
        case FETCH_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                info: action.info,
                wishlists: action.wishlists,
                activelist: action.activelist
            });
        case FETCH_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case ACTIVATE_LIST_SUCCESS:
            return Object.assign({}, state, {
                activelist: action.id,
                wishlists: state.wishlists.map(wl => ({ ...wl, active: wl.id === action.id }))
            });
        case SET_ACCOUNT_INFO:
            return setAccountInfo(state, action);
        case SET_LISTS:
            return setLists(state, action);
        case CREATE_LIST_SUCCESS:
            return Object.assign({}, state, {
                wishlists: [
                    ...state.wishlists.map(wl => ({
                        ...wl, active: false
                    })),
                    action.wishlist
                ],
                activelist: action.wishlist.id
            });
        case SET_ACTIVE_LIST:
            return setActiveList(state, action);
        default:
            return state;
    }
};

export default account;
