import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import account from "./account";
import wishlist from "./wishlist";
import { RESTORE_VALID_SCHEMA } from "../constants/ActionTypes";

const combinedReducer = combineReducers({
    app,
    auth,
    account,
    wishlist
});

const rootReducer = (state, action) => {
    if (action.type === RESTORE_VALID_SCHEMA) {
        return combinedReducer(undefined, action);
    }

    return combinedReducer(state, action);
};

rootReducer.initialState = combinedReducer.initialState;

export default rootReducer;
