export function updateObject(oldObject, newValues) {
    return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, itemId, updateItemCallback) {
    const updatedItems = array.map(item => {
        if (item.products_id !== itemId) {
            return item;
        }
        const updatedItem = updateItemCallback(item);

        return updatedItem;
    });

    return updatedItems;
}

export function addItemToArray(oldObject, array, item) {
    return Object.assign({}, oldObject, {
        array: [
            ...array,
            item
        ]
    });
}
