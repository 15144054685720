import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import "../styles/components/flashMessage.scss";
import { clearFlashMessage } from "../redux/actions";
import CloseIconWhite from "../img/icons/close-white.svg";
import { useHistory } from "react-router-dom";

function FlashMessage(props) {
    const history = useHistory();
    const { clearFlashMessage, message, flashMessagePath } = props;
    const dismissTimeout = useRef(0);

    useEffect(() => {
        return history.listen((location, action) => {
            if (location.pathname !== flashMessagePath && action !== "REPLACE") {
                clearFlashMessage();
            }
        });
    }, [ clearFlashMessage, flashMessagePath, history ]);

    // When the message changes, set or reset a timeout to dismiss the message after a few seconds
    useEffect(() => {
        if (message) {
            dismissTimeout.current = setTimeout(() => {
                clearFlashMessage();
            }, 8000);
        }
        return () => clearTimeout(dismissTimeout.current);
    }, [ clearFlashMessage, message ]);

    if (message === null) { return <></>; }
    return (
        <div className="c-flash-message visible">
            <div className="message">{message}</div>
            <button className="close" onClick={clearFlashMessage}><img className="light-icon" src={ CloseIconWhite } alt="" /></button>
        </div>
    );
}

const mapStateToProps = state => ({
    message: state.app.flash_message,
    message_type: state.app.flash_message_type,
    flashMessagePath: state.app.flash_message_path
});
const mapDispatchToProps = {
    clearFlashMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
